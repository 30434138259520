import { useQuery } from '@tanstack/react-query'
import TableButtonAction from 'components/Buttons/TableButtons/TableButtonAction'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import { IColumnVisibility } from 'components/DataTable/types'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import DistributorAssignModal from 'components/Modals/AssignmentModal/DistributorAssignModal'
import TagAssignModal from 'components/Modals/AssignmentModal/TagAssignModal'
import ContactCompanyExportModal from 'components/Modals/ExportModal/ContactCompanyExportModal'
import OpportunitiesTableFooterControls from 'components/Opportunities/OpportunitiesTableFooterControls'
import { CompaniesFilterset } from 'components/Tables/CompaniesTable/CompaniesFilterset'
import { getCampaignTableKey, UGC_TABLE_KEY } from 'constants/tableQueryKeys'
import { Category, SubCategory } from 'models/companies'
import { ContactResponse } from 'models/contacts'
import React, { useEffect, useMemo, useState } from 'react'
import {
  BsBriefcase,
  BsDownload,
  BsTags,
  BsTruck,
  BsXCircle,
} from 'react-icons/bs'
import apiService from 'services/api'
import styled from 'styled-components'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { FilterIdentifier } from '../../../models/saved_view'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import { CreateDealModalForTable } from '../../Deals/CreateDealModal/CreateDealModal'

import { TableSearch } from '../../Filters/TableSearch'
import * as S from '../CommonTable.styles'
import { AssignToCampaignModal } from '../../../features/campaigns/AssignToCampaignModal'
import { IDropdownItem } from '../../UI/Dropdown/Dropdown'
import { useCampaignUnassigner } from '../../../features/campaigns/useCampaignUnassigner'
import { featureFlagService } from '../../../utils/featureFlagService'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import { cn } from '../../UI/cn'
import { dataTableSortingStoreRepo } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { useCompaniesTableCols } from './useCompaniesTableColumns'

type SortableFields = (keyof ContactResponse | string)[]

const sortableFields: SortableFields = [
  'name',
  'created',
  'note_count',
  'deal_count',
  'contact_count',
  'sales_stages',
  'state',
  'city',
  'country',
]

const PAGE_SIZE = 100

interface CompaniesTableProps {
  setTotalRowsCount?: (count: number) => void
  pageParamName?: string
  category: Category
  subCategory: SubCategory
  tableKey: string
  filterIdentifierModifier?: FilterIdentifier
  baseFilters?: Record<string, any>
}

function CompaniesTableComponent(props: CompaniesTableProps) {
  const hasCampaign = !!props.baseFilters?.campaigns
  const IDENTIFIER =
    `companies-${props.subCategory?.id}${props.filterIdentifierModifier ?? ''}` as FilterIdentifier
  const companiesFilterStore = filterStoreRepo.getStore(IDENTIFIER)
  const api = apiService()
  const featureFlag = featureFlagService()
  const {
    state: { totalSelectedRows },
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = useDataTableContext()
  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()

  const [pagination, setPagination] = usePaginationURLParams(
    PAGE_SIZE,
    props.pageParamName
  )

  const filterParams = {
    ...useFilterParams(companiesFilterStore),
    ...props.baseFilters,
  }

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}
    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }
    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      sub_category_id: props.subCategory?.id,
      ...filterParams,
      ...sortParams,
    }),
    [filterParams, sortParams]
  )

  const TABLE_QUERY_KEY = [
    hasCampaign
      ? getCampaignTableKey(UGC_TABLE_KEY, props.baseFilters?.campaign)
      : UGC_TABLE_KEY, // Common key for all UGC tables
    ...getTableQueryKey({
      tableKey: props.tableKey,
      filterParams: filterAndSortParams,
      page: pagination.pageIndex + 1,
    }),
  ]

  // DATA FETCHING
  const { isFetching, data } = useQuery({
    staleTime: Infinity,
    queryKey: TABLE_QUERY_KEY,
    queryFn: async () => {
      clearSelectedRows()
      const res = await api.getCompaniesList(props.subCategory?.id as number, {
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        ...filterAndSortParams,
      })
      props.setTotalRowsCount?.(res.count)
      setTotalRowsInBackend(res.count)
      return res
    },
  })
  // END DATA FETCHING

  const unassignFromCampaign = useCampaignUnassigner(props.subCategory.slug, {
    data: data?.results ?? [],
    accessorKey: 'id',
    tableQueryKey: TABLE_QUERY_KEY,
    requestParams: filterAndSortParams,
    campaignId: props?.baseFilters?.campaigns,
  })

  useEffect(() => {
    clearSelectedRows()
  }, [data])

  const [showAssignTags, setShowAssignTags] = useState(false)
  const [showAssignDistributors, setShowAssignDistributors] = useState(false)
  const [openDealModal, setOpenDealModal] = React.useState(false)
  const [showExportAllModal, setShowExportAllModal] = useState(false)

  const handleCreateDeals = () => {
    if (!data) return
    setOpenDealModal(true)
  }

  const columns = useCompaniesTableCols({
    sorting,
    inCampaigns: props.baseFilters?.campaigns,
  })

  const apiTagsAction = api.companiesBulkEditTags.bind(
    // @ts-expect-error idk why this errors..
    this,
    props.subCategory?.id as number
  )
  const apiDistributorsAction = api.companiesBulkEditDistributors.bind(
    // @ts-expect-error idk why this errors..
    this,
    props.subCategory?.id as number
  )

  const actions = useMemo(() => {
    const items: IDropdownItem[] = [
      {
        label: 'Create Deals',
        icon: <BsBriefcase size={20} />,
        callback: handleCreateDeals,
      },
      {
        label: 'Manage Tags',
        icon: <BsTags size={20} />,
        callback: () => setShowAssignTags(true),
      },
      {
        label: 'Add Known Distributors',
        icon: <BsTruck size={20} />,
        callback: () => setShowAssignDistributors(true),
      },
    ]

    if (featureFlag.enableCampaigns) {
      if (!hasCampaign) {
        items.push({
          label: 'Push to Campaign',
          icon: <BsDownload size={20} />,
          subContent: (
            <AssignToCampaignModal
              data={data?.results ?? []}
              accessorKey={'id'}
              tableQueryKey={TABLE_QUERY_KEY}
              requestParams={filterAndSortParams}
              companyTypeSlug={props.subCategory.slug}
              buttonLess
            />
          ),
        })
      } else {
        items.push({
          label: 'Unassign from Campaign',
          icon: <BsXCircle size={20} />,
          callback: () => unassignFromCampaign.unassign(),
        })
      }
    }

    return items
  }, [data, featureFlag, hasCampaign])

  useEffect(() => {
    const totalCount = data?.count ?? 0
    props.setTotalRowsCount?.(totalCount)
    setTotalRowsInBackend?.(totalCount)
  }, [data?.count])

  return (
    <TableContainer>
      <DataTable
        tableHeader={
          <S.SearchContainer>
            <div className="flex w-full gap-2 items-center">
              <TableSearch
                className={'flex-1'}
                filterStore={companiesFilterStore}
                searchPlaceholder="Search by name, company..."
              />
              <CompaniesFilterset
                filterIdentifier={IDENTIFIER}
                subCategoryId={props.subCategory?.id as number}
                hideCampaignsFilter={props.baseFilters?.campaign}
              />
              <ColumnSelectorRecipient tableKey={props.tableKey} />
            </div>

            <div className={cn('flex flex-row justify-between gap-4')}>
              <TableButtonAction
                items={actions}
                disabled={!totalSelectedRows}
                selectedRowsCount={totalSelectedRows ?? 0}
              />
              <FilterChips
                identifier={'COMPANIES_TABLE'}
                clearAllButton
                showActive
                store={companiesFilterStore}
              />
            </div>
          </S.SearchContainer>
        }
        tableKey={props.tableKey}
        loading={isFetching || unassignFromCampaign.isUnassigning}
        data={data?.results ?? []}
        columns={columns}
        sortableFields={sortableFields}
        virtualizeRows={false}
        enableRowSelection
        isPaginationEnabled={true}
        paginationOptions={{
          pageCount: Math.ceil((data?.count ?? 0) / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        selectAllText={'Select Opportunities'}
        footerControls={
          <OpportunitiesTableFooterControls
            companyTypeSlug={props.subCategory.slug}
            onDownloadButtonClick={() => setShowExportAllModal(true)}
          />
        }
      />

      <TagAssignModal
        show={showAssignTags}
        handleClose={() => setShowAssignTags(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        filterAndSortParams={filterAndSortParams}
        apiTagsAction={apiTagsAction}
        idAccessor="id"
      />

      <DistributorAssignModal
        show={showAssignDistributors}
        handleClose={() => setShowAssignDistributors(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="id"
        filterAndSortParams={filterAndSortParams}
        apiDistributorsAction={apiDistributorsAction}
      />

      <ContactCompanyExportModal
        count={data?.count ?? 0}
        show={showExportAllModal}
        _handleClose={() => {
          setShowExportAllModal(false)
        }}
        subCategoryName={props.subCategory?.name as string}
        filters={filterAndSortParams}
      />
      {openDealModal && (
        <CreateDealModalForTable
          optimisticUiTarget="companies"
          show={openDealModal}
          handleClose={() => setOpenDealModal(false)}
          queryKey={TABLE_QUERY_KEY}
          filterAndSortParams={filterAndSortParams}
          data={data?.results ?? []}
          idAccessor="id"
          originCampaignId={props.baseFilters?.campaign}
          companyTypeSlug={props.subCategory.slug}
        />
      )}
    </TableContainer>
  )
}

CompaniesTableComponent.displayName = 'CompaniesTable'

export function CompaniesTable(
  props: CompaniesTableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      savedPresetsIdentifier={
        `companies-${props.subCategory?.id}` as FilterIdentifier
      }
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={['Opportunity Attributes', 'Campaigns and Deals']}
    >
      <DataTableProvider tableKey={tableKey}>
        <CompaniesTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`
