import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import apiService from 'services/api'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import * as S from '../CommonTable.styles'

import to from 'await-to-js'
import { AxiosError } from 'axios'
import TableButtonAction from 'components/Buttons/TableButtons/TableButtonAction'
import TableButtonProduct from 'components/Buttons/TableButtons/TableButtonProduct'
import { IColumnVisibility } from 'components/DataTable/types'
import {
  restaurantsFilterStore,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import DistributorAssignModal from 'components/Modals/AssignmentModal/DistributorAssignModal'
import TagAssignModal from 'components/Modals/AssignmentModal/TagAssignModal'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import DoorExportModal from 'components/Modals/ExportModal/DoorExportModal'
import OpportunitiesTableFooterControls from 'components/Opportunities/OpportunitiesTableFooterControls'
import { DoorsFilterset } from 'components/Tables/DoorsTable/DoorsFilterset'
import { DOOR_SUMMARY_KEY } from 'constants/tableQueryKeys'
import { BsDownload, BsTags, BsTruck } from 'react-icons/bs'
import {
  bulkEditDistributorsParams,
  bulkEditTagsParams,
} from 'services/apiTypes'
import { notifyError } from 'services/toast'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { useSelectedProductIds } from 'stores/SelectedProductsStore/SelectedProductsStore'
import { paramsContainAccountFilters } from 'utils/filterUtils'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { PlacesSummary } from '../../../models/summaries'
import { useOpportunitiesContext } from '../../../pages/app/opportunities/opportunities-context'

import { dataTableSortingStoreRepo } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { DataTableContainer } from '../../DataTable/UI'

import { TableSearchWithElastic } from '../../Filters/TableSearchWithElastic'
import { DoorMenuModal } from '../../Modals/MenuModal/DoorMenuModal'
import { Switch } from '../../UI/Switch/Switch'
import { cn } from '../../UI/cn'
import { ChangeProductViewModal } from '../../change-product-view-modal'
import { useRestaurantsTableColumns } from './DoorsTableColumns'

const sortableFields = [
  'restaurant_name',
  'chain',
  'total_ltv',
  'brand_ltv',
  'revenue_ltv',
  'pounds_per_year',
  'reviews_count',
  'expense_category',
  'chain_count',
  'sales_stage',
  'account_owner',
  'dmanamecbsa',
  'avg_rating',
  'cuisine_50',
  'country',
  'state',
  'city',
  'zipcode',
  'domain',
  'velocity_group',
  'deal_count',
  'contact_count',
  'taro',
  'tabo',
  'one_year_total_value',
  'distributors',
  'michelin_stars',
  'menu_size',
  'menu_matches',
  'sales_stages',
  'instagram_text_percentile',
]

interface IDoorsTableProps {
  chainFilter?: number
  setDoorsSummary?: React.Dispatch<React.SetStateAction<PlacesSummary>>
  isChainTable?: boolean
  hideTitle?: boolean
  disableUrlPagination?: boolean
  tableKey: string
  tableKeyForColumnOrdering?: string
}

const PAGE_SIZE = 100

function DoorsTableComponent({
  hideTitle = true,
  disableUrlPagination = false,
  ...props
}: IDoorsTableProps) {
  const tableContext = useDataTableContext()
  const queryClient = useQueryClient()
  const api = apiService()

  const { setRestaurantsView, chainsTabSelected, doorsTabSelected } =
    useOpportunitiesContext()
  const [menuModal, setMenuModal] = useState<
    | {
        doorId: number
        doorName?: string
        itemsIds?: number[]
        menuLength?: number
      }
    | undefined
  >(undefined)

  // TABLE CONTEXT (SORTING, ETC)
  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()
  const {
    state: { rowSelection, totalSelectedRows, isAllRowsSelected },
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = tableContext

  const selectedProductsIds = useSelectedProductIds()

  const [pagination, setPagination] = disableUrlPagination
    ? useState({
        pageIndex: 0,
        pageSize: PAGE_SIZE,
      })
    : usePaginationURLParams(PAGE_SIZE)

  const [showProductSelection, setShowProductSelection] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const [showAssignTags, setShowAssignTags] = useState(false)
  const [showAssignDistributors, setShowAssignDistributors] = useState(false)
  const [showExportAllModal, setShowExportAllModal] = useState(false)

  const filtersAsParams = useFilterParams(restaurantsFilterStore)

  // FILTER PARAMS (FOR API CALL)
  const filterParams = useMemo(() => {
    const params: Record<string, unknown> = { ...filtersAsParams }

    if (props.chainFilter) {
      params.chain = props.chainFilter
    }

    return params
  }, [filtersAsParams, props.chainFilter])

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...filterParams,
      ...sortParams,
    }),
    [filterParams, sortParams]
  )

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
    products: selectedProductsIds,
  })

  // API CALL
  const { data: places, isFetching } = useQuery({
    staleTime: Infinity,
    queryKey: TABLE_QUERY_KEY,
    enabled: doorsTabSelected || !!props.chainFilter, // Enable only when tab is selected or chain filter is present (chain page)
    queryFn: async ({ signal }) => {
      clearSelectedRows()
      const [err, res] = await to(
        api.getRestaurantsV2(
          {
            ...filterAndSortParams,
            page: pagination.pageIndex + 1,
            limit: pagination.pageSize,
            product_id: selectedProductsIds,
          },
          signal
        )
      )

      if ((err as AxiosError)?.code === 'ERR_NETWORK') {
        notifyError('Sorry! Unable to complete this search, please try again.')
      }
      if (err) throw err

      return res.results
    },
  })

  useEffect(() => {
    clearSelectedRows()
  }, [places])

  // SUMMARY API CALL
  const {
    data: placesSummary = {
      brand_ltv: 0,
      revenue_ltv: 0,
      total_ltv: 0,
      chains_count: 0,
      places_count: 0,
    },
    isFetching: placesSummaryLoading,
  } = api.useGetPlacesSummaryV2(
    {
      ...filterParams,
      product_id: selectedProductsIds.filter((p) => p !== -1),
    },
    undefined,
    !props.isChainTable
  )

  // ˆ PASS LTV RESULTS TO PARENT COMPONENT ˆ
  useEffect(() => {
    props.setDoorsSummary?.({
      ...placesSummary,
      isLoading: placesSummaryLoading,
    })
    setTotalRowsInBackend(placesSummary.places_count ?? 0)
  }, [placesSummaryLoading, placesSummary])

  const selectedDoorIds = useMemo(() => {
    const accessor = 'firstbite_id'
    return (
      places
        ?.filter((_, i: number) => rowSelection[i])

        ?.map((r: any) => {
          return accessor.split('.').reduce((acc, key) => acc[key], r)
        }) ?? []
    )
  }, [places, rowSelection])

  const unselectedDoorIds = useMemo(() => {
    if (!isAllRowsSelected) return []
    return (
      places
        ?.filter((_, i: number) => !rowSelection[i])
        ?.map((r: any) => r.firstbite_id) ?? []
    )
  }, [places, rowSelection, isAllRowsSelected])

  const columns = useRestaurantsTableColumns(
    setMenuModal,
    !!props.isChainTable,
    props.chainFilter,
    selectedProductsIds,
    sorting,
    false
  )

  const refetchDoorsSummary = useCallback(() => {
    if (paramsContainAccountFilters(filterAndSortParams)) {
      queryClient.refetchQueries({ queryKey: [DOOR_SUMMARY_KEY] })
    }
  }, [filterAndSortParams, queryClient])

  const apiTagsAction = useCallback(
    async (values: bulkEditTagsParams) => {
      const response = await api.doorsBulkEditTags(values)
      refetchDoorsSummary()
      return response
    },
    [api, refetchDoorsSummary]
  )

  const apiDistributorsAction = useCallback(
    async (values: bulkEditDistributorsParams) => {
      const response = await api.doorsBulkEditDistributors(values)
      refetchDoorsSummary()
      return response
    },
    [api, refetchDoorsSummary]
  )

  return (
    <DataTableContainer>
      <DataTable
        tableHeader={
          <>
            {!hideTitle && (
              <Row className="mb-4">
                <Col>
                  <S.CardTitle>
                    Doors{' '}
                    <span style={{ fontWeight: 100 }}>
                      ({placesSummary?.places_count})
                    </span>
                  </S.CardTitle>
                </Col>
              </Row>
            )}
            {!props.isChainTable && (
              <>
                <S.SearchContainer>
                  <div className="flex w-full gap-2 items-center">
                    <TableSearchWithElastic
                      filterStore={restaurantsFilterStore}
                      historyKey="doors"
                      options={[
                        {
                          label: 'Menu',
                          searchKey: 'menu',
                          searchPlaceholder: 'Type menu item',
                        },
                        {
                          label: 'Name',
                          searchKey: 'restaurant_name',
                          searchPlaceholder: 'Type restaurant name',
                        },
                      ]}
                    />

                    <Switch
                      type={'checkbox'}
                      checked={chainsTabSelected}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setRestaurantsView('chain')
                        } else {
                          setRestaurantsView('door')
                        }
                      }}
                      labelRight={
                        chainsTabSelected ? 'Chain View' : 'Door View'
                      }
                      className={'min-w-[120px]'}
                    />

                    <TableButtonProduct
                      selectedProducts={selectedProductsIds}
                      onClick={() => setShowProductSelection(true)}
                    />

                    <DoorsFilterset />

                    <ColumnSelectorRecipient tableKey={props.tableKey} />
                  </div>

                  <div className={cn('flex flex-row justify-between gap-4')}>
                    <TableButtonAction
                      items={[
                        {
                          label: 'Manage Tags',
                          icon: <BsTags size={20} />,
                          callback: () => setShowAssignTags(true),
                        },
                        {
                          label: 'Add Known Distributors',
                          icon: <BsTruck size={20} />,
                          callback: () => setShowAssignDistributors(true),
                        },
                        {
                          label: 'Export Selected',
                          icon: <BsDownload size={20} />,
                          callback: () => setShowExportModal(true),
                        },
                      ]}
                      disabled={!totalSelectedRows}
                      selectedRowsCount={totalSelectedRows ?? 0}
                    />
                    <FilterChips
                      identifier={'DOORS_TABLE'}
                      clearAllButton
                      showActive
                      store={restaurantsFilterStore}
                    />
                  </div>
                </S.SearchContainer>

                <TagAssignModal
                  show={showAssignTags}
                  handleClose={() => setShowAssignTags(false)}
                  tableQueryKey={TABLE_QUERY_KEY}
                  data={places ?? []}
                  filterAndSortParams={filterAndSortParams}
                  apiTagsAction={apiTagsAction}
                  idAccessor="chain_proxy.chain.id"
                />

                <DistributorAssignModal
                  show={showAssignDistributors}
                  handleClose={() => setShowAssignDistributors(false)}
                  tableQueryKey={TABLE_QUERY_KEY}
                  data={places ?? []}
                  idAccessor="chain_proxy.chain.id"
                  filterAndSortParams={filterAndSortParams}
                  apiDistributorsAction={apiDistributorsAction}
                />
              </>
            )}
            {props.isChainTable && (
              <S.SearchContainer>
                <div className="flex w-full justify-end">
                  <ColumnSelectorRecipient tableKey={props.tableKey} />
                </div>
              </S.SearchContainer>
            )}
          </>
        }
        tableKey={props.tableKey}
        data={places ?? []}
        loading={isFetching}
        columns={columns}
        sortableFields={sortableFields}
        virtualizeRows={true}
        enableRowSelection={!props.chainFilter}
        isPaginationEnabled={true}
        defaultSort={[{ id: 'chain_count', desc: true }]}
        paginationOptions={{
          pageCount: Math.ceil(placesSummary?.places_count / PAGE_SIZE),
          setPagination: setPagination,
          isPaginationLoading: placesSummaryLoading,
          pagination: pagination,
        }}
        selectAllText="Select Opportunities"
        footerControls={
          <OpportunitiesTableFooterControls
            companyTypeSlug={'restaurants-bars'}
            onDownloadButtonClick={() =>
              chainsTabSelected
                ? setShowExportAllModal(true)
                : setShowExportModal(true)
            }
          />
        }
      />

      <ChangeProductViewModal
        show={showProductSelection}
        handleClose={() => setShowProductSelection(false)}
      />

      {menuModal?.doorId && (
        <DoorMenuModal
          filterStore={restaurantsFilterStore}
          show={!!menuModal}
          handleClose={() => setMenuModal(undefined)}
          doorId={menuModal.doorId!}
          doorName={menuModal.doorName!}
          menuLength={menuModal?.menuLength}
        />
      )}

      <DoorExportModal
        filters={filterAndSortParams}
        productSelected={selectedProductsIds[0]}
        show={showExportModal || !!showExportAllModal}
        _handleClose={() => {
          setShowExportModal(false)
          setShowExportAllModal?.(false)
        }}
        selectedIds={
          showExportModal && !isAllRowsSelected ? selectedDoorIds : undefined
        }
        excludeIds={isAllRowsSelected ? unselectedDoorIds : undefined}
        count={
          showExportModal
            ? selectedDoorIds?.length
            : placesSummary?.places_count
        }
      />
    </DataTableContainer>
  )
}

export function DoorsTable(
  props: IDoorsTableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const {
    tableKey,
    tableKeyForColumnOrdering,
    defaultColumnVisibility,
    ...rest
  } = props

  return (
    <ColumnsStoreProvider
      savedPresetsIdentifier={'DOORS_TABLE'}
      tableKey={tableKeyForColumnOrdering ?? tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={[
        'Opportunity Attributes',
        'Campaigns and Deals',
        'Predicted Values',
        'Reputation Data',
        'Location Data',
      ]}
    >
      <DataTableProvider tableKey={tableKey}>
        <DoorsTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
