import { dealsPipelineFilterStore } from 'components/Filters/FilterStore'
import FilterModal, {
  FilterGroup,
} from 'components/Filters/components/FilterModal'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { useCategorySubcategoryOptions } from 'hooks/useCategorySubcategoryFilterOptions'
import apiService from 'services/api'
import { DateRangeFilter } from '../../Filters/fields/DateRangeFilter'
import { RangeFilter } from '../../Filters/fields/RangeFilter'
import { FILTER_TITLES } from '../../Filters/filterTitles'

import { FilterIdentifier } from 'models/saved_view'
import { useMemo } from 'react'

const IDENTIFIER: FilterIdentifier = 'DEALS_PIPELINE_TABLE'

export default function DealsPipelineTableFilters() {
  const api = apiService()
  const { data: userOptions } = api.useGetUserOptions()
  const { data: stageOptions } = api.useGetSalesStageOptions()
  const { data: products } = api.useGetProductsOptions()
  const { categoriesOptions, subCategoriesOptions } =
    useCategorySubcategoryOptions({
      filterStore: dealsPipelineFilterStore,
    })

  const { data: campaigns } = api.useGetCampaigns()
  const campaignOptions = useMemo(() => {
    const options =
      campaigns?.map((c) => ({
        label: c.name,
        value: c.id.toString(),
        icon: (
          <div
            className="h-3 w-3 rounded-full flex-shrink-0"
            style={{ backgroundColor: c.color }}
          />
        ),
      })) ?? []
    options.push({
      label: 'Without Campaign',
      value: '-1',
      icon: (
        <div
          className="h-3 w-3 rounded-full flex-shrink-0"
          style={{ backgroundColor: '#fff' }}
        />
      ),
    })
    return options
  }, [campaigns])

  return (
    <FilterModal store={dealsPipelineFilterStore} identifier={IDENTIFIER}>
      <FilterGroup title="Account">
        <SelectFilter
          filterStore={dealsPipelineFilterStore}
          filterKey="sales_stage"
          title={FILTER_TITLES[IDENTIFIER].sales_stages}
          options={stageOptions as any}
        />
        <SelectFilter
          filterKey="account_owner"
          title={FILTER_TITLES[IDENTIFIER].account_owner}
          options={userOptions as any}
          filterStore={dealsPipelineFilterStore}
        />
        <SelectFilter
          title={FILTER_TITLES[IDENTIFIER].campaigns}
          filterStore={dealsPipelineFilterStore}
          filterKey="campaigns"
          options={campaignOptions}
        />
        <SelectFilter
          title={FILTER_TITLES[IDENTIFIER].product}
          filterStore={dealsPipelineFilterStore}
          filterKey="product"
          options={products as any}
        />
        <RangeFilter
          store={dealsPipelineFilterStore}
          filterKey="count"
          title={FILTER_TITLES[IDENTIFIER].count}
        />
        <RangeFilter
          store={dealsPipelineFilterStore}
          filterKey="closed"
          title={FILTER_TITLES[IDENTIFIER].closed}
        />
        <RangeFilter
          store={dealsPipelineFilterStore}
          filterKey="taro"
          title={FILTER_TITLES[IDENTIFIER].taro}
        />
        <RangeFilter
          store={dealsPipelineFilterStore}
          filterKey="taro_closed"
          title={FILTER_TITLES[IDENTIFIER].taro_closed}
        />
        <DateRangeFilter
          title={FILTER_TITLES[IDENTIFIER].last_modified}
          filterKey={'last_modified'}
          filterStore={dealsPipelineFilterStore}
        />
        <RangeFilter
          title={FILTER_TITLES[IDENTIFIER].days_since_last_modified}
          filterKey={'days_since_last_modified'}
          store={dealsPipelineFilterStore}
        />
      </FilterGroup>
      <FilterGroup title={'Opportunity Details'}>
        <SelectFilter
          title={FILTER_TITLES[IDENTIFIER].category}
          filterKey={'category'}
          filterStore={dealsPipelineFilterStore}
          options={categoriesOptions}
        />
        <SelectFilter
          title={FILTER_TITLES[IDENTIFIER].sub_category}
          filterKey={'sub_category'}
          filterStore={dealsPipelineFilterStore}
          options={subCategoriesOptions}
        />
      </FilterGroup>
    </FilterModal>
  )
}
